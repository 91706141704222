import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "We vs They",
  "date": "2016-04-04T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I’ve been interviewing a lot these days at Shopify, so I’ve started to pick up on certain patterns I see when talking to people in interviews. One that I started noticing is how people talk about their past experiences.`}</p>
    <p>{`Some people will say: “They were doing marketing websites for various clients” and others will say: “We were doing marketing websites for various clients”. It’s a subtle difference, but to me it highlights the level of investment the person had into their work. Did they have a sense of ownership? Were they allowed to make decisions?`}</p>
    <p>{`In a way, saying “we” means you consider yourself part of the team. It means you were engaged in making decisions and that you had an important role that company or project.`}</p>
    <p>{`This is just a small example, but the `}<em parentName="p">{`way`}</em>{` you say things can often tell people more about you that `}<em parentName="p">{`what`}</em>{` you’re saying. Noticing these details will make you a better interviewer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      